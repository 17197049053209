import React, { useState } from 'react';
import Sidebar from '../../Components/Sidebar/sidebar'
import {Outlet} from 'react-router-dom'
import '../Dashboard/dashboard.css'
export default function Dashboard() {
  const [toggel, Settoggle] = useState(false)
  return (
    <>
        <Sidebar toggel={toggel} Settoggle={Settoggle}/>

        <div className='main-content p-3' style={!toggel? {left: '78px', width: 'calc(100% - 78px)'}: {left: '315px', width: 'calc(100% - 315px)'}}>
          {/* <Jobview /> */}
          <Outlet/>
        </div>
    </>

  )
}
