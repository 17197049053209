import React from "react";
import { BiArrowBack } from "react-icons/bi";
import "../AreaMaster/liststyle.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { FIELD_REQUIRED_ERROR } from "../../Common/Validation/errorMessage";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { PostData } from "../../Common/Api";

export default function Tagmasteradd() {
    
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      await PostData("/tags", data);
      navigate("/tagmaster");
      reset()
    } catch (error) {
      console.log(error);
    }
  };

  
  return (
    <>
      <div className="newJobList-box border p-3">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">タグマスターの追加</h2>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              navigate("/tagmaster");
            }}
          >
            <BiArrowBack /> 戻る
          </button>
        </div>
        <div className="form">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  className="mb-3 quform-element"
                  controlId="State Name"
                >
                   <div className='input-text-box'>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Tag Name *"
                    {...register("tag_name", {
                      required: FIELD_REQUIRED_ERROR(
                        "Tag Name"
                      ),
                    })}
                  />
                  {errors?.tag_name ? (
                        <span className="error">
                          {errors?.tag_name?.message}
                        </span>
                      ) : null}
                  </div>
                </Form.Group>
              </div>
              <div className="col-12 text-end">
                <button type="button" className="btn btn-warning me-3" onClick={()=> reset()} >クリアテキスト </button>
                <button type="submit" className="btn btn-secess">
                  保存
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
