import React from "react";
import { BiArrowBack } from "react-icons/bi";
import "../AreaMaster/liststyle.css";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { FIELD_REQUIRED_ERROR } from "../../Common/Validation/errorMessage";
import { useForm } from "react-hook-form";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { GetData, PostData } from "../../Common/Api";
import { useEffect } from "react";
import { useState } from "react";
export default function ResidentsAssociationMasteradd() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const [areaMaster, setAreaMaster] = useState([]);

  const onSubmit = async (data) => {
    try {
      await PostData("/residents_association", data);
      navigate("/residentsmaster");
      reset()
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(()=>{
    FatchData()
  },[])
  const FatchData = async () => {
    try {
      const res = await GetData("/area/all");
      setAreaMaster(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="newJobList-box border p-3">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">自治会マスターを追加</h2>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              navigate("/residentsmaster");
            }}
          >
            <BiArrowBack /> 戻る
          </button>
        </div>
        <div className="form">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
              <div className="select-box">
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                            Area Id
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Residentsa ssociation"
                              {...register("area_id", {
                                required: FIELD_REQUIRED_ERROR(
                                  "area association id"
                                ),
                              })}
                              value={watch("area_id") || ""}
                              // displayEmpty
                            >
                               <MenuItem value={""}> Please Select</MenuItem>
                              {areaMaster.length > 0 &&
                                areaMaster.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.area_id}
                                    >
                                      {item.area_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                <Form.Group
                  className="mb-3 quform-element mt-3"
                  controlId="Residents Association Name"
                >
                  <div className="input-text-box">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      name="residents_association_name"
                      label="Residents Association Name *"
                      {...register("residents_association_name", {
                        required: FIELD_REQUIRED_ERROR(
                          "Residents Association Name"
                        ),
                      })}
                    />
                    {errors?.residents_association_name ? (
                      <span className="error">
                        {errors?.residents_association_name?.message}
                      </span>
                    ) : null}
                  </div>
                </Form.Group>
              </div>
              <div className="col-12 text-end">
                <button type="button" className="btn btn-warning me-3" onClick={()=> reset()} >クリアテキスト </button>
                <button type="submit" className="btn btn-secess">
                  保存
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
