import React from "react";
import { Navigate } from "react-router-dom";

export const PrivetRoute = ({children}) => {
  const token = localStorage.getItem("token");
  return token ? children : <Navigate to="/login" />
};


export const PublicRoute = ({children}) =>{
    const token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/" />;
  }

  return children;
}