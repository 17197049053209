import React, {useState}  from 'react'
import { BiArrowBack } from 'react-icons/bi';
import '../AreaMaster/liststyle.css'
import Form from 'react-bootstrap/Form';
import {useNavigate} from 'react-router-dom'
import { PostData } from '../../Common/Api';
import { FIELD_REQUIRED_ERROR } from '../../Common/Validation/errorMessage';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';

export default function AreaMasteradd() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
       
      } = useForm();
      const navigate = useNavigate();
      const onSubmit = async (data) => {
        try {
          await PostData("/area", data);
          navigate("/areamaster");
          reset()
        } catch (error) {
          console.log(error);
        }
      };
    
    
  return (
    <>
       <div className='newJobList-box border p-3'>
        <div className='main-tittle mb-4'>
            <h2 className='mb-0'>
エリアマスターの追加</h2>
            <button type='button' className='btn btn-warning' onClick={()=>{navigate("/areamaster")}}><BiArrowBack /> 戻る</button>
        </div>
        <div className='form'>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-md-12'>
                        <Form.Group className="mb-3 quform-element" controlId="Area Name">
                        <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Area Name *"
                        {...register("area_name", {
                          required: FIELD_REQUIRED_ERROR("Residents Area Name"),
                        })}
                      />
                      {errors?.area_name ? (
                        <span className="error">
                          {errors?.area_name?.message}
                        </span>
                      ) : null}
                        </Form.Group>
                    </div>
                    <div className='col-12 text-end'>
                        <button type='button' className='btn btn-warning me-3'  onClick={()=> reset()} >クリアテキスト </button>
                        <button type='submit' className='btn btn-secess'>保存</button>
                    </div>
                </div>
            </Form>
        </div>
       </div>
    </>

  )
}
