import axios from "axios";
import toast from "react-simple-toasts";
import { CODE_SUCCESS, INTERNAL_SERVER, TOKEN_ERROR, baseURL } from "./common.js";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});



export const GetData = async (endpoint) => {
  try {
    const response = await axiosInstance.get(endpoint);
    if (response.status === CODE_SUCCESS) {
      return response.data;
    }
  } catch (error) {

    if (error.response.status === INTERNAL_SERVER) {
      toast(`${error.response.data.error}`);
    }
    if (error.response.status === TOKEN_ERROR) {
      toast(`${error.response.data.error}`);
      localStorage.removeItem("token");
      window.location.href = "/Login";
    }
  }
};

export const PostData = async (endpoint, data) => {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    if (error.response.status === INTERNAL_SERVER) {
      toast(`${error.response.data.error}`);
    }
    if (error.response.status === TOKEN_ERROR) {
      toast(`${error.response.data.error}`);
      localStorage.removeItem("token");
      window.location.href = "/Login";
    }
  }
};

export const PutData = async (endpoint, data) => {
  try {
    const response = await axiosInstance.put(endpoint, data);
    return response.data;
  } catch (error) {
    if (error.response.status === INTERNAL_SERVER) {
      toast(`${error.response.data.error}`);
    }
    if (error.response.status === TOKEN_ERROR) {
      toast(`${error.response.data.error}`);
      localStorage.removeItem("token");
      window.location.href = "/Login";
    }
  }
};

export const DeleteData = async (endpoint) => {
  try {
    const response = await axiosInstance.delete(endpoint);
    return response.data;
  } catch (error) {
    if (error.response.status === INTERNAL_SERVER) {
      toast(`${error.response.data.error}`);
    }
    if (error.response.status === TOKEN_ERROR) {
      toast(`${error.response.data.error}`);
      localStorage.removeItem("token");
      window.location.href = "/Login";
    }
  }
};
