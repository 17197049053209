import React, { useEffect, useState }  from 'react'
import { BiArrowBack } from 'react-icons/bi';
import '../AreaMaster/liststyle.css'
import Form from 'react-bootstrap/Form';
import {useNavigate} from 'react-router-dom'
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import { FIELD_REQUIRED_ERROR } from '../../Common/Validation/errorMessage';
import { GetData, PostData } from '../../Common/Api';

export default function Adduserform() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const [residentData, setResidentData] = useState([]);
   useEffect(()=>{
    fatchResidentsMaster()
   },[])
  const fatchResidentsMaster = () => {
    GetData(`/residents_association/all`)
      .then((res) => {
        setResidentData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      await PostData("/users", data);
      navigate("/users");
      reset()
    } catch (error) {
      console.log(error);
    }
  };
    
  return (
    <>
       <div className='newJobList-box border p-3'>
        <div className='main-tittle mb-4'>
            <h2 className='mb-0'>
ユーザーマスターを追加</h2>
            <button type='button' className='btn btn-warning' onClick={()=>{navigate("/users")}}><BiArrowBack /> 戻る</button>
        </div>
        <div className="form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element"
                      controlId="Email"
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Email *"
                        {...register("email", {
                          required: FIELD_REQUIRED_ERROR("Email"),
                        })}
                       
                      />
                      {errors?.email ? (
                        <span className="error">
                          {errors?.email?.message}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element"
                      controlId="Password"
                    >
                      <TextField type="password" label="Password *"  {...register("password", {
                          required: FIELD_REQUIRED_ERROR("password"),
                        })}
                        
                      />
                      {errors?.password ? (
                        <span className="error">
                          {errors?.password?.message}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element"
                      controlId="Password"
                    >
                      <div className="select-box">
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                            住民協会
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Residentsa ssociation"
                              {...register("residents_association_id", {
                                required: FIELD_REQUIRED_ERROR(
                                  "residents association id"
                                ),
                              })}
                              value={watch("residents_association_id") || ""}
                              // displayEmpty
                            >
                               <MenuItem value={""}> Please Select</MenuItem>
                              {residentData.length > 0 &&
                                residentData.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.residents_association_id}
                                    >
                                      {item.residents_association_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-end">   
                    <button type='button' className='btn btn-warning me-3' onClick={()=> reset()} >クリアテキスト </button>
                        <button type='submit' className='btn btn-secess' >保存</button>
                  </div>
                </div>
              </Form>
            </div>
       </div>
    </>

  )
}
