import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "../AreaMaster/liststyle.css";
import { AiOutlineEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { DeleteData, GetData, PutData } from "../../Common/Api";
import { FIELD_REQUIRED_ERROR } from "../../Common/Validation/errorMessage";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Adduser() {
  const navigate = useNavigate();
  const [userMaster, setUserMaster] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [id, setId] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();

  const handleShow2 = (id) => {
    setShow2(true);
    setId(id);
  };
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };
  useEffect(() => {
    if (id) {
      FatchDataid();
    }
    FatchData();
    fatchResidentsMaster();
  }, [id,show2, show]);

  const handleClose2 = () => {
    setShow2(false);
    reset();
  };
  const handleClose = () => {
    setShow(false);
    reset();
  };
  const FatchData = async () => {
    try {
      const res = await GetData("/users/all");
      setUserMaster(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const FatchDataid = () => {
    GetData(`/users/${id}`)
      .then((res) => {
        setValue("email", res.data.email);
        setValue("password", res.data.password);
        setValue("residents_association_id", res.data.residents_association_id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [residentData, setResidentData] = useState([]);

  const fatchResidentsMaster = () => {
    GetData(`/residents_association/all`)
      .then((res) => {
        setResidentData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onSubmit = async (data) => {
    try {
      const res = await PutData(`/users/${id}`, data);
      FatchData();
      reset();
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteDatas = (id) => {
    DeleteData(`/users/${id}`)
      .then((response) => {
        reset();
        FatchData();
        setShow2(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">新規ユーザーマスター</h2>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              navigate("/users/add");
            }}
          >
            新しく追加する
          </button>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>いいえ。</th>
                <th>Eメール</th>
                <th>アクション</th>
              </tr>
            </thead>
            <tbody>
              {userMaster.length > 0 &&
                userMaster.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.email}</td>
                      <td className="action-btns d-flex">
                        <div className="edit-btn me-2">
                          <AiOutlineEdit
                            onClick={() => handleShow(item.user_id)}
                          />
                        </div>
                        <div className="delete-btn">
                          <AiFillDelete
                            onClick={() => handleShow2(item.user_id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>編集 ユーザーの追加</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element"
                      controlId="Email"
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Email *"
                        {...register("email", {
                          required: FIELD_REQUIRED_ERROR("Email"),
                        })}
                        InputLabelProps={{
                          shrink: !!watch("email"),
                        }}
                      />
                      {errors?.email ? (
                        <span className="error">{errors?.email?.message}</span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element"
                      controlId="Password"
                    >
                      <TextField
                        type="password"
                        label="Password *"
                        {...register("password", {
                          required: FIELD_REQUIRED_ERROR("password"),
                        })}
                        InputLabelProps={{
                          shrink: !!watch("email"),
                        }}
                      />
                      {errors?.password ? (
                        <span className="error">
                          {errors?.password?.message}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className="col-md-12 mb-3">
                    {/* <Form.Group
                      className="mb-3 quform-element"
                      controlId="Password"
                    >
                      <div className="select-box">
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Residentsa ssociation
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Residentsa ssociation"
                              {...register("residents_association_id", {
                                required: FIELD_REQUIRED_ERROR(
                                  "residents association id"
                                ),
                              })}
                              InputLabelProps={{
                                shrink: !!watch("residents_association_id"),
                              }}
                            >
                               <MenuItem value={""}> Please Select</MenuItem>
                              {residentData.length > 0 &&
                                residentData.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item.residents_association_id}
                                    >
                                      {item.residents_association_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </Form.Group> */}
                    <div className="form-floating did-floating-label-content">
                      <select
                        className="form-select did-floating-select"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        {...register("residents_association_id", {
                          required: FIELD_REQUIRED_ERROR(
                            "residents association id"
                          ),
                        })}
                      >
                        <option value="">Open this select menu</option>
                        {residentData.length > 0 &&
                          residentData.map((item) => {
                            return (
                              <option value={item.residents_association_id}>
                                {" "}
                                {item.residents_association_name}
                              </option>
                            );
                          })}
                      </select>
                      <label
                        for="floatingSelect"
                        className="did-floating-label"
                      >
                        自治会
                      </label>
                    </div>
                    {errors?.residents_association_id ? (
                      <span className="error">
                        {errors?.residents_association_id?.message}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 text-end">
                    <button
                      type="button"
                      className="btn btn-warning me-3"
                      onClick={handleClose}
                    >
                      キャンセル
                    </button>
                    <button className="btn btn-secess" type="submit">
                      保存
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-popup text-center">
              <h2 className="mt-4 mb-4">本気ですか？</h2>
              <p>
                本当にこれらのレコードを削除してもよろしいですか?このプロセスを元に戻すことはできません。
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-warning me-3" onClick={handleClose2}>
              キャンセル
            </Button>
            <Button className="btn btn-secess" onClick={() => deleteDatas(id)}>
              消去
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
