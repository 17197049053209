import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../AreaMaster/liststyle.css";
import { AiOutlineEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { DeleteData, GetData, PutData } from "../../Common/Api";
import { FIELD_REQUIRED_ERROR } from "../../Common/Validation/errorMessage";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
export default function Residentsmaster() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();

  const [residents, setResidents] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [id, setId] = useState();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);

  const handleShow2 = (id) => {
    setShow2(true);
    setId(id);
  };
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };
  useEffect(() => {
    if (id) {
      FatchDataid();
    }
    FatchData();
  }, [id,show2, show]);

  const FatchData = async () => {
    try {
      const res = await GetData("/residents_association/all");
      setResidents(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const FatchDataid = async () => {
    const res = await GetData(`/residents_association/${id}`);
    setValue("area_id", res.data.area_id);
    setValue("residents_association_name", res.data.residents_association_name);
  };

  const onSubmit = async (data) => {
    try {
      const res = await PutData(`/residents_association/${id}`, data);
      FatchData();
      setShow(false);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDatas = (id) => {
    DeleteData(`/residents_association/${id}`)
      .then((response) => {
        FatchData();
        reset();
        setShow2(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [areaMaster, setAreaMaster] = useState([]);
  useEffect(() => {
    FatchDatas();
  }, []);
  const FatchDatas = async () => {
    try {
      const res = await GetData("/area/all");
      setAreaMaster(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">自治会マスター</h2>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              navigate("/residentsmaster/add");
            }}
          >
            新しく追加する
          </button>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>シニアNo.</th>
                <th>エリアID</th>
                <th>自治会名</th>
                <th>アクション</th>
              </tr>
            </thead>
            <tbody>
              {residents.length > 0 &&
                residents.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.area_id}</td>
                      <td>{item.residents_association_name}</td>
                      <td className="action-btns d-flex">
                        <div className="edit-btn me-2">
                          <AiOutlineEdit
                            onClick={() =>
                              handleShow(item.residents_association_id)
                            }
                          />
                        </div>
                        <div className="delete-btn">
                          <AiFillDelete
                            onClick={() =>
                              handleShow2(item.residents_association_id)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>自治会マスターの編集</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form>
                <div className="row">
                  <div className="col-md-12">
                   
                    <div class="form-floating  did-floating-label-content mb-4">
                      <select
                        class="form-select did-floating-select"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        {...register("area_id", {
                          required: FIELD_REQUIRED_ERROR("area id"),
                        })}
                      >
                        <option value="">Open this select menu</option>
                        {areaMaster.length > 0 &&
                          areaMaster.map((item) => {
                            return (
                              <option value={item.area_id}>
                                {" "}
                                {item.area_name}
                              </option>
                            );
                          })}
                      </select>
                      <label
                        for="floatingSelect"
                        className="did-floating-label"
                      >
                        Area Id*{" "}
                      </label>
                    </div>
                    {errors?.area_id ? (
                      <span className="error">{errors?.area_id?.message}</span>
                    ) : null}
                    <Form.Group
                      className="mb-3 quform-element"
                      controlId="Residents Association Name"
                    >
                      <div className="input-text-box">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          type="text"
                          name="residents_association_name"
                          label="Residents Association Name *"
                          {...register("residents_association_name", {
                            required: FIELD_REQUIRED_ERROR(
                              "Residents Association Name"
                            ),
                          })}
                          InputLabelProps={{
                            shrink: !!watch("residents_association_name"), // Set shrink to true if there's a value
                          }}
                        />
                        {errors?.residents_association_name ? (
                          <span className="error">
                            {errors?.residents_association_name?.message}
                          </span>
                        ) : null}
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-end">
                    <button
                      type="button"
                      className="btn btn-warning me-3"
                      onClick={handleClose}
                    >
                      キャンセル
                    </button>
                    <button
                      type="button"
                      className="btn btn-secess"
                      onClick={handleSubmit(onSubmit)}
                    >
                      保存
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-popup text-center">
              <h2 className="mt-4 mb-4">本気ですか？</h2>
              <p>
                本当にこれらのレコードを削除してもよろしいですか?このプロセスを元に戻すことはできません。
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-warning me-3" onClick={handleClose2}>
              キャンセル
            </Button>
            <Button className="btn btn-secess" onClick={() => deleteDatas(id)}>
              消去
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
