import React, { useState } from "react";
import "../Sidebar/sidebar.css";
import { FaBars } from "react-icons/fa";
import { AiOutlineInfo, AiOutlineTag } from "react-icons/ai";
import { AiFillDashboard } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineAreaChart } from "react-icons/ai";
import { GiMatterStates } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineUserAdd } from "react-icons/ai";
import { GrStatusInfo } from "react-icons/gr";

export default function Sidebar({ toggel, Settoggle }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [toggel1, Settoggle1] = useState(true);
  let sidebar = document.querySelector(".sidebar");
  let sidebarBtn = document.querySelector(".bx-menu");
  sidebarBtn?.addEventListener("click", () => {
    sidebar?.classList.toggle("close");
  });
  return (
    <>
      <div className={toggel ? "sidebar" : "sidebar close"}>
        <div className="logo-details">
          <span className="logo_name text-center">
            {/* <img src='/assets/images/job-logo.png' className='img-fluid w-75 m-auto'></img> */}
          </span>
        </div>
        <ul className="nav-links pt-0">
          <li>
            <Link
              to="/"
              className={`${location.pathname == "/" ? "active-sidbar" : ""}`}
            >
              <AiFillDashboard className="bx bx-grid-alt" />
              <span className="link_name">ダッシュボード</span>
            </Link>
          </li>
          <li className={toggel1 ? "" : "active showMenu"}>
            <div className="iocn-link">
              <Link
                to="/areamaster"
                className={`${
                  location.pathname == "/areamaster" ? "active-sidbar" : ""
                }`}
              >
                <AiOutlineAreaChart className="bx" />
                <span className="link_name">エリアマスター</span>
              </Link>
            </div>
          </li>
          <li className={toggel1 ? "" : "active showMenu"}>
            <div className="iocn-link">
              <Link
                to="/residentsmaster"
                className={`${
                  location.pathname == "/residentsmaster" ? "active-sidbar" : ""
                }`}
              >
                <AiOutlineHome className="bx" />
                <span className="link_name">自治会マスター</span>
              </Link>
            </div>
          </li>
          <li className={toggel1 ? "" : "active showMenu"}>
            <div className="iocn-link">
              <Link
                to="/statesmaster"
                className={`${
                  location.pathname == "/statesmaster" ? "active-sidbar" : ""
                }`}
              >
                <GiMatterStates className="bx" />
                <span className="link_name">ステートマスター</span>
              </Link>
            </div>
          </li>
          <li className={toggel1 ? "" : "active showMenu"}>
            <div className="iocn-link">
              <Link
                to="/tagmaster"
                className={`${
                  location.pathname == "/tagmaster" ? "active-sidbar" : ""
                }`}
              >
                <AiOutlineTag className="bx" />
                <span className="link_name">タグマスター</span>
              </Link>
            </div>
          </li>

          <li className={toggel1 ? "" : "active showMenu"}>
            <div className="iocn-link">
              <Link
                to="/statusmaster"
                className={`${
                  location.pathname == "/statusmaster" ? "active-sidbar" : ""
                }`}
              >
                <AiOutlineInfo className="bx" />
                <span className="link_name">ステータスマスター</span>
              </Link>
            </div>
          </li>
          <li className={toggel1 ? "" : "active showMenu"}>
            <div className="iocn-link">
              <Link
                to="/users"
                className={`${
                  location.pathname == "/users" ? "active-sidbar" : ""
                }`}
              >
                <AiOutlineUserAdd className="bx" />
                <span className="link_name">ユーザー</span>
              </Link>
            </div>
          </li>
          <li className={toggel1 ? "" : "active showMenu"}>
            <div className="iocn-link">
              <Link
                to="/adminusers"
                className={`${
                  location.pathname == "/adminusers" ? "active-sidbar" : ""
                }`}
              >
                <AiOutlineUser className="bx" />
                <span className="link_name"> 管理者ユーザー</span>
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <section className="home-section">
        <div className="home-content">
          <FaBars
            className="bx bx-menu"
            onClick={() => {
              toggel ? Settoggle(false) : Settoggle(true);
            }}
          />
          <div className="header  p-3  text-end">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <AiOutlineUser />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    localStorage.removeItem("token");
                    navigate("/login")
                  }}
                >
                  loggout
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </section>
    </>
  );
}
