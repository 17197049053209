
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './Pages/Dashboard/dashboard';
import {Route ,Routes} from 'react-router-dom'
import Residentsmaster from './Pages/ResidentsMaster/Residentsmaster';
import Tagmaster from './Pages/TagMaster/tagmaster';
import Statesmaster from './Pages/StateMaster/statemaster';
import ResidentsAssociationMasteradd from './Pages/ResidentsMaster/addresidentsmaster';
import Statemasteradd from './Pages/StateMaster/addstatemaster';
import Tagmasteradd from './Pages/TagMaster/addtagmaster';
import AreaMaster from './Pages/AreaMaster/AreaMaster';
import AreaMasteradd from './Pages/AreaMaster/areamasteradd';
import Login from './Pages/Login/Login';
import { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css'; // choose your theme
import { PrivetRoute, PublicRoute } from './routes/routes';
import Adduserform from './Pages/Adduser/adduserform';
import Adduser from './Pages/Adduser/adduser';
import StatusMaster from './Pages/StatusMaster/statusmaster';
import StatusMasteradd from './Pages/StatusMaster/addstusmaster';
import AdminUser from './Pages/adminusers/adminuser';
import Addadminform from './Pages/adminusers/adminuseradd';
import Home from './Pages/Dashboard/Home';

toastConfig({ theme: 'dark' });

function App() {
  return (
     <>
     <Routes>
      <Route exact path ="/" element ={<PrivetRoute> <Dashboard/></PrivetRoute>} >
      <Route exact path ="" element ={ <Home/>} />
      <Route exact path ="areamaster" element ={ <AreaMaster/>} />
      <Route exact path ="areamaster/add" element ={ <AreaMasteradd/>} />
      <Route exact path ="residentsmaster" element ={ <Residentsmaster/>} />
      <Route exact path ="residentsmaster/add" element ={ <ResidentsAssociationMasteradd/>} />
      <Route exact path ="statesmaster" element ={ <Statesmaster/>} />
      <Route exact path ="statesmaster/add" element ={ <Statemasteradd/>} />
      <Route exact path ="tagmaster" element ={ <Tagmaster/>} />
      <Route exact path ="tagmaster/add" element ={ <Tagmasteradd/>} />
      <Route exact path ="statusmaster" element ={ <StatusMaster/>} />
      <Route exact path ="statusmaster/add" element ={ <StatusMasteradd/>} />
      <Route exact path ="users" element ={ <Adduser/>} />
      <Route exact path ="users/add" element ={ <Adduserform/>} />
      <Route exact path ="adminusers" element ={ <AdminUser/>} />
      <Route exact path ="addadminusers/add" element ={ <Addadminform/>} />
      </Route>
      <Route exact path ="/login" element = {<PublicRoute><Login /></PublicRoute>} />
     </Routes>
     
     </>
  );
}

export default App;
