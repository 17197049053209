import React from 'react'
import '../Dashboard/dashboard.css'
const Home = () => {
  return (
    <div className='comingsoonrent'>
    <div className="containerdata">
    <div className="mainText">
      <h1 className="title">Dashboard</h1>
      <div className="comingSoon">
        <span className="comingSoonText">Coming</span>
        <span className="comingSoonText">Soon</span>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Home
