import { useForm } from "react-hook-form";
import React, { useState } from "react";
import "./login.css";
import {
  FIELD_REQUIRED_ERROR,
  INVALID_FIELD_ERROR,
} from "../../Common/Validation/errorMessage";
import { EMAIL_REGEX } from "../../Common/Validation/regex";
import { useNavigate } from "react-router-dom";
import toast from "react-simple-toasts";
import axios from "axios";
import { BAD_REQUEST, CODE_SUCCESS, INTERNAL_SERVER, baseURL } from "../../Common/common";


const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    axios
      .post(`${baseURL}/auth/login`, values)

      .then(function (response) {
        if (response.status === CODE_SUCCESS) 
        {
          localStorage.setItem("token",response.data.data.token);
          window.location.href ="/"
        }
      })
      .catch(function (error) {
         if(error.message == "Network Error"){
          toast(`${error.message}`);
         }
        if (error.response.status === INTERNAL_SERVER) {
          toast(`${error.response.data.error}`);
        }
        if (error.response.status === BAD_REQUEST) {
          toast(`${error.response.data.error}`);
        }
        console.log(error)
      }).finally(function (error) {
        console.log(error)
      });
  };
  return (
    <>
      <div className="login-main">
        <form className="login" onSubmit={handleSubmit(onSubmit)}>
          <h2>Welcome, User!</h2>
          <p>Please log in</p>
          <input
            type="email"
            placeholder="User Name"
            {...register("email", {
              required: FIELD_REQUIRED_ERROR("Email"),
              pattern: {
                value: EMAIL_REGEX,
                message: INVALID_FIELD_ERROR("email address"),
              },
            })}
          />
          {errors?.email ? (
            <span className="error">{errors?.email?.message}</span>
          ) : null}
          <input
            type="password"
            placeholder="Password"
            {...register("password", {
              required: FIELD_REQUIRED_ERROR("Password"),
            })}
          />
          {errors?.password ? (
            <span className="error">{errors?.password?.message}</span>
          ) : null}
          <input type="submit" value="Log In" />
          {/* <div className="links"> */}
          {/* <a href="#">Forgot password</a>
          <a href="#">Register</a> */}
          {/* </div> */}
        </form>
      </div>
    </>
  );
};

export default Login;
