import React, { useEffect, useState } from "react";
import "../AreaMaster/liststyle.css";
import { AiOutlineEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { DeleteData, GetData, PutData } from "../../Common/Api";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { FIELD_REQUIRED_ERROR } from "../../Common/Validation/errorMessage";
export default function Tagmaster() {
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [id, setId] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();

  const handleClose2 = () => {setShow2(false);  reset()};
  const handleClose = () => {setShow(false);  reset()};

  const handleShow2 = (id) => {
    setShow2(true);
    setId(id);
  };

  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  useEffect(() => {
    if (id) {
      FatchDataid();
    }
    FatchData();
  }, [id,show2, show]);

  const FatchData = async () => {
    try {
      const res = await GetData("/tags/all");
      setTags(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const FatchDataid = async () => {
    const res = await GetData(`/tags/${id}`);
    setValue("tag_name", res.data.tag_name);
  };
  const onSubmit = async (data) => {
    try {
      const res = await PutData(`/tags/${id}`, data);
      FatchData();
      setShow(false);
      reset()
    } catch (error) {
      console.log(error);
      reset()
    }
  };
  const deleteDatas = (id) => {
    DeleteData(`/tags/${id}`)
      .then((response) => {
        FatchData();
        setShow2(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <div className="JobList-box">
        <div className="main-tittle mb-4">
          <h2 className="mb-0">
タグマスター</h2>
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => {
              navigate("/tagmaster/add");
            }}
          >
            新しく追加する
          </button>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>シニアNo.</th>
                <th>タグ名</th>
                <th>
アクション</th>
              </tr>
            </thead>
            <tbody>
              {tags.length > 0 &&
                tags.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.tag_name}</td>
                      <td className="action-btns d-flex">
                        <div className="edit-btn me-2">
                          <AiOutlineEdit
                            onClick={() => handleShow(item.tag_id)}
                          />
                        </div>
                        <div className="delete-btn">
                          <AiFillDelete
                            onClick={() => handleShow2(item.tag_id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>状態マスターの編集</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group
                      className="mb-3 quform-element"
                      controlId="State Name"
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Tag Name *"
                        {...register("tag_name", {
                          required: FIELD_REQUIRED_ERROR("Residents Tag Name"),
                        })}
                        InputLabelProps={{
                          shrink: !!watch("tag_name"),
                        }}
                      />
                      {errors?.residents_association_name ? (
                        <span className="error">
                          {errors?.residents_association_name?.message}
                        </span>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="col-12 text-end">
                    <button
                      type="button"
                      className="btn btn-warning me-3"
                      onClick={handleClose}
                    >
                      
キャンセル
                    </button>
                    <button type="submit" className="btn btn-secess">
                      保存
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="delete-popup text-center">
              <h2 className="mt-4 mb-4">本気ですか？</h2>
              <p>
              本当にこれらのレコードを削除してもよろしいですか?このプロセスを元に戻すことはできません。
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-warning me-3" onClick={handleClose2}>
            キャンセル
            </Button>
            <Button className="btn btn-secess" onClick={() => deleteDatas(id)}>
            
消去
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
